<template>
    <div class="flex-box vertical content md">
        <div class="data-title">
            <div class="rec-title-text">活动统计</div>
            <a-form-model class="query-form" layout="inline">
                <a-form-model-item label="日期">
                    <a-range-picker
                        v-model="form.time"
                        :placeholder="['开始时间', '结束时间']"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                        @change="getCount"/>
                </a-form-model-item>
            </a-form-model>
            <div class="title-btn">
                <a-button type="primary" @click="getCount">刷新</a-button>
            </div>
        </div>
        <div class="act-count-area">
            <div class="act-count-item">
                <div class="act-count-num"><span>{{count[0]}}</span>次</div>
                <div class="act-count-name">打卡</div>
            </div>
            <div class="act-count-item">
                <div class="act-count-num"><span>{{count[1]}}</span>次</div>
                <div class="act-count-name">祝福红包</div>
            </div>
            <div class="act-count-item">
                <div class="act-count-num"><span>{{count[2]}}</span>次</div>
                <div class="act-count-name">投稿</div>
            </div>
        </div>
        <div class="data-title">
            <div class="rec-title-text">投稿列表</div>
        </div>
        <div class="data-area">
            <a-table
                    ref="dataTable"
                    :columns="columns"
                    row-key="id"
                    :data-source="list"
                    :loading="loading"
                    :pagination="pagination"
                    :scroll="{y: h}"
                    @change="handleTableChange">
                <template v-slot:name="text">
                    <open-data type="userName" :openid="text" />
                </template>
                <template v-slot:action="text, record">
                    <div class="row-btn">
                        <a class="txt-btn" @click.stop="showDetail(record)">查看</a>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RecActivity",
        data() {
            return {
                form: {},
                list: [],
                count: [0,0,0],
                loading: false,
                h: 500,
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                },
                columns: [
                    {title: '投稿时间', dataIndex: 'create_time'},
                    {title: '用户昵称', dataIndex: 'username'},
                    {title: '状态', dataIndex: 'statusText'},
                    {title: '操作', key: 'action', scopedSlots: { customRender: 'action'}}
                ]
            }
        },
        created() {
            this.getCount();
            this.getList();
        },
        methods: {
            getCount() {
                let url = '/admin/mini-activity/get-count';
                let time = this.form.time;
                let params = {}
                if(time && time.length == 2) {
                    params.start = time[0];
                    params.end = time[1];
                }
                this.$axios(url, {noTempleFilter: true, params}).then(res => {
                    if(res) {
                        let punch = res.find(item => item.type == 2);
                        let packet = res.find(item => item.type == 3);
                        let manu = res.find(item => item.type == 4);
                        this.count = [punch ? punch.count : 0, packet ? packet.count : 0, manu ? manu.count : 0];
                    }
                })
            },
            getList() {
                this.loading = true;
                let url = `/admin/mini-activity?sort=-id&filter[type]=4`;
                let {current, pageSize} = this.pagination;
                url += `&page=${current}&pageSize=${pageSize}`;
                this.$axios({url, noTempleFilter: true}).then(res => {
                    this.loading = false;
                    let list = res.data;
                    list.forEach(item => {
                        this.dealManuscript(item);
                    });
                    this.list = list;
                    if(res.page) {
                        this.$set(this.pagination, 'total', res.page.totalCount);
                    }
                }).catch(() => {
                    this.loading = false;
                });
            },
            dealManuscript(item) {
                item.username = item.miniUser && item.miniUser.uname ? item.miniUser.uname : '微信用户';
                let detail = item.detail;
                if(detail) {
                    try {
                        let temp = JSON.parse(detail);
                        if(temp.reply) {
                            item.statusText = '已回复';
                        } else {
                            item.statusText = '未回复';
                        }
                    } catch (e) {
                        item.statusText = '未回复';
                        console.error(e)
                    }
                }
            },
            showDetail(record) {
                this.$router.push({name: 'RecManuscript', params: {id: record.id}});
            },
            handleTableChange(pagination) {
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
                this.getList();
            },
        }
    }
</script>

<style scoped lang="less">
    .act-count-area {
        display: flex;
        margin-bottom: 16px;
        padding: 24px;
    }
    .act-count-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 18px;
        &:not(:first-child) {
            border-left: var(--border);
        }
    }
    .act-count-num {
        font-size: 12px;
        span {
            font-size: 24px;
            font-weight: 600;
            color: @orange-6;
        }
    }
    .act-count-name {
        margin-top: 10px;
    }
</style>
